.search_item_container {
    width: 100%;
    margin: 0 auto;
    background-color: white;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
    border-radius: 1em;
    color: green;
    text-align: center;

}
.search_item_container:hover {
    background-color: orange;
    color: white;
}
.search_item_image {
    width: 100%;
    margin: 1em auto;
}
.search_item_image img {
    margin: auto;
    height: 5em;
    max-width: 90%;
    border-radius: 1em;
}
.search_item_name {
    font-size: 1.2em;
    font-weight: 400;
    margin: .5em 0;
}





@media only screen and (min-width: 481px) {
    .search_item_container {
        width: 90%;
        margin: 0 auto;
        background-color: white;
        box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
        border-radius: 1em;
        color: green;
        text-align: center;
    
    }
    .search_item_container:hover {
        background-color: orange;
        color: white;
    }
    .search_item_image {
        width: 90%;
        margin: 1em auto;
    }
    .search_item_image img {
        margin: auto;
        height: 8em;
        max-width: 90%;
        border-radius: 1em;
    }
    .search_item_name {
        font-size: 1.2em;
        font-weight: 400;
        
        margin: .5em 0;
    }
    
}
