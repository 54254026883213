.hero_list_container {
    width: 90%;
    margin: 4.5em auto 0 auto;
}

@media only screen and (min-width: 481px) {
    .hero_list_container {
        width: 90%;
        margin: 6em auto 2em auto;
    }
}