.login_container {
    width: 90%;
    margin: 3em auto 1em;
    
}
.login_container h1, .login_container h2 {
    text-align: center;
    font-size: 1.5em;
    padding: 0;
    margin: 0.5em 0;
    color: #062539;
}
.login_form {
    width: 100%;
    box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 0.03);
    border-radius: .5em;
    padding: 1em 1em;
    margin-top: 1em;
}
.Login_input_group {
    width: 100%;
    margin: .2em auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Login_input_group label {
    margin: .7em 0;
}
.remeber_forgot {
    width: 100%;
    margin: 2em auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.remember label {
    margin-left: .5em;
}
.login_submit {
    width: 100%;
    margin: .5em auto;
}
.sign_input{
    width: 100%;
    margin: 1em 0;
    border-radius: .3em;
    font-size: 1.1em;
    font-weight: 600;
    height: 2.2em;
    /* background-color: orange; */
    color: white;
    border: none;
    text-align: center;
}
.sign_up {
    width: 100%;
    display: flex;
    margin: 0 auto;
    text-decoration: none;
}
.link {
    text-decoration: none;
}
.error_show {
    display: block;
    width: 100%;
    margin: .5em auto;
    color: red;
}
.error_hide {
    display: none;
}

@media only screen and (min-width: 481px) {
    .login_container {
        width: 35%;
        margin: 5em auto;
        
    }
    .login_container h1, .login_container h2 {
        text-align: center;
        font-size: 1.5em;
        padding: 0;
        margin: 1em 0;
        color: #062539;
    }
    .login_form {
        width: 100%;
        box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 0.03);
        border-radius: .5em;
        padding: 1.5em 0;
        margin-top: 1em;
    }
    .Login_input_group {
        width: 80%;
        margin: 1.3em auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .Login_input_group label {
        margin: .7em 0;
    }
    .remeber_forgot {
        width: 80%;
        margin: 1em auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .remember label {
        margin-left: .5em;
    }
    .login_submit {
        width: 80%;
        margin: .5em auto;
    }
    .sign_input{
        width: 100%;
        margin: 1em 0;
        border-radius: .3em;
        font-size: 1.1em;
        height: 2.2em;
        /* background-color: #062539; */
        color: white;
        border: none;
        text-align: center;
    }
    .sign_up {
        width: 80%;
        display: flex;
        margin: 0 auto;
        text-decoration: none;
    }
    .link {
        text-decoration: none;
    }
    .error_show {
        display: block;
        width: 80%;
        margin: 0 auto;
        color: red;
    }
    .error_hide {
        display: none;
    }
}