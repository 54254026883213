.App {
  width: 100%;
  margin: 0;
  padding: 0;
}

.label {
  width: 90%;
  margin: 1.5em auto .5em auto;
  padding: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: green;
}