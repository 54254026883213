.product_list {
    width: 100%;
    background-color: #EDFAE8;
}
.product_list_container {
    width: 90%;
    margin: 0 auto;
}
.product_list_skeleton_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.skeleten_item {
    flex: .3;
}
.skeleten_item Skeleton {

}
.skeleten_item_lap {
    display: none;
}
.no_product {
    text-align: center;
    width: 100%;
    font-size: 1em;
    font-weight: 600;
    padding: 1em 0;
}
@media only screen and (min-width: 481px) {
    
    .product_list_skeleton_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .skeleten_item_lap {
        display: block;
    }
    .product_list {
        width: 100%;
        background-color: #EDFAE8;
        padding: 3em 0;
    }
    .product_list_container {
        width: 90%;
    }
    .no_product {
        text-align: center;
        width: 100%;
        font-size: 1.2em;
        font-weight: 400;
        padding: 0;
    }
    .slider {
        width: 100%;
    }
}