p, h3 {
    padding: 0;
    margin: 0;
}
.cart_item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}
.item_image {
    flex: .2;
    border-radius: 2em;
}
.item_image img {
    width: 100%;
    margin: 10%;
    padding: 0;
}
.item_info {
  flex: .4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item_imagee {
    flex: .2;
    width: 20%;
    border-radius: 2em;
}

.item_infoo {
  flex: .8;
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_text {
    margin-bottom: 1em;
}
.quantity_container {
    margin: 0;
    padding: 0;
}
.quantity_input_cart {
    margin: 0;
}
.delete_btn {
    font-size: 1.8em;
    color: orangered;
}