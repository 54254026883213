.cold_room_container {
    width: 100%;
    margin: .5em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    border-radius: 1em;
}
.cold_room_label {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: .5em auto;
}
.cold_room_label p {
    margin: .3em;
}
.map {
    width: 100%;
    height: 15em;
    border-radius: 1em 1em 0 0;
    z-index: 0;
    position: relative;
    /* background-color: black; */
    /* mix-blend-mode: multiply;
    background-blend-mode: multiply; */
}
.on_map {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: green;
    mix-blend-mode: darken; */
    z-index: 1000;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.on_map a {
    color: white;
    font-size: 2em;
    font-weight: 700;
    z-index: 2000;
}
.name {
    font-size: 1.1em;
    font-weight: 600;
}
.distance {
    font-size: 1em;
    font-weight: 500;
}

@media only screen and (min-width: 481px) {
    .cold_room_container {
        width: 100%;
        margin: .5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
        border-radius: 1em;
    }
    .cold_room_label {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: .5em auto;
    }
    .cold_room_label p {
        margin: .3em;
    }
    .map {
        width: 100%;
        height: 15em;
        border-radius: 1em 1em 0 0;
        z-index: 0;
        position: relative;
        /* background-color: black; */
        /* mix-blend-mode: multiply; */
        /* background-blend-mode: multiply; */
    }
    .on_map {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .7;
        /* mix-blend-mode: darken; */
        z-index: 10000000;
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .direction a {
        color: white;
        font-size: 1.3em;
        font-weight: 700;
        z-index: 20000000;
        
    }
    .direction {
        padding: .5em;
        border-radius: 2em;
        border: 1px solid green;
        background-color: green;
        color: black;
        z-index: 10000000;
        box-shadow: 2px 5px 5px rgb(0 0 0 / 0.1);
    }
    .name {
        flex: .6;
        font-size: 1em;
        font-weight: 600;
    }
    .distance {
        flex-direction: .4;
        font-size: 1em;
        font-weight: 500;
    }
}