.about_us_list_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3em auto;
    background-color: aliceblue;   
}
.about_us_list_container h2 {
    text-align: center;
    margin: 2em 0;
    font-size: 1.2em;
    color: green;
    font-weight: 700;
}
.about_us_list {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}
@media only screen and (min-width: 481px) {
    .about_us_list_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 4em auto;
        padding: 1em 0;
        background-color: aliceblue;
           
    }
    .about_us_list_container h2 {
        text-align: center;
        margin-top: 2em;
        color: green;
        font-weight: 700;
    }
    .about_us_list {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin:  2em auto 0 auto;
    }
}