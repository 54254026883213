.search_page_container {
    width: 90%;
    margin: 6em auto;
}
.search_heading {
    margin: 2em 0;
}
.heading_text {
    color: green;
    font-size: 1.5em;
    font-weight: 600;
    margin: 1em 0;
}
.no_search {
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.no_search_icon {
   font-size: 6em;
   color: green;
}
.no_search_title {
    font-size: 1.6em;
    margin: .5em 0;
    font-weight: 600;
}
.no_search_detail {
    font-size: 1.1em;
    margin: .4em 0;
    font-weight: 400;
}
.search_item_list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3em;
    column-gap: 1em;
}

@media only screen and (min-width: 481px) {
    .search_page_container {
        width: 90%;
        margin: 6em auto;
    }
    .search_heading {
        margin: 2em 0;
    }
    .heading_text {
        color: green;
        font-size: 1.5em;
        font-weight: 600;
        margin: 1em 0;
    }
    .no_search {
        width: 50%;
        margin: 0 auto;
        text-align: center;
    }
    .no_search_icon {
       font-size: 6em;
       color: green;
    }
    .no_search_title {
        font-size: 1.6em;
        margin: .5em 0;
        font-weight: 600;
    }
    .no_search_detail {
        font-size: 1.1em;
        margin: .4em 0;
        font-weight: 400;
    }
    .search_item_list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 3em;
        column-gap: 2em;
    }
}