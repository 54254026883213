.bottom_bar {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.09);
    
}
.bottom_nav {
    width: 100%;
    padding: 1em 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    align-items: flex-start;
    border-radius: 1em 1em 0 0;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
}
.bottom_menu_icon {
    font-size: 2em;
    color: green;
}
.home_active {
    color: orange;
}
.account_menuu {
    width: 100%;
}
.account_menu_item {
    width: 100%;
    color: black;
    font-size: 1.4em;
    font-weight: 500;
}
.account_menu_item:hover {
    background-color: orange;
    color: white;
}

@media only screen and (min-width: 481px) {
    .bottom_bar {
        display: none;
    }
}