.cart_menu_container {
    position: relative;
    
}

.cart_icon {
    font-size: 2rem;
    color: green;
}
.cart_circle {
    position: absolute;
    padding: .05rem 0;
    top: -.8rem;
    left: 1.3rem;
    background-color: rgba(255, 166, 2, 0.719);
    text-align: center;
    border-radius: 100%;
    /* border: 1px solid green; */
    width: 1.6rem;
    height: 1.6rem;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
}
.cart_circle_text {
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
    /* margin: .2rem; */
}