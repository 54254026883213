.confirmation_container {
    width: 90%;
    margin: 7em auto;
    text-align: center;
}
.confirm_text {
    margin: 1.5em 0;
}
.confirmed_icon {
    color: green;
    font-size: 3em;
}
.title_one {
    font-size: 1.5em;
    font-weight: 700;
    color: green;
}
.title_two {
    font-size: 1.2em;
    font-weight: 600;
    color: green;
}
.title_three {
    font-size: 1em;
    font-weight: 500;
}
.back_to {
    width: 15em;
    height: 2.5em;
    font-size: 1.1em;
    font-weight: 600;
    color: green;
}

@media only screen and (min-width: 481px) {
    .confirmation_container {
        width: 60%;
        margin: 7em auto;
        text-align: center;
    }
    .confirm_text {
        margin: 1.5em 0;
    }
    .confirmed_icon {
        color: green;
        font-size: 3em;
    }
    .title_one {
        font-size: 1.5em;
        font-weight: 700;
        color: green;
    }
    .title_two {
        font-size: 1.2em;
        font-weight: 600;
        color: green;
    }
    .title_three {
        font-size: 1em;
        font-weight: 500;
    }
    .back_to {
        width: 15em;
        height: 2.5em;
        font-size: 1.1em;
        font-weight: 600;
        color: green;
    }
}