.cart_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5em auto 2em auto;
    z-index: 0;
    justify-content: space-between;
}
.item_list {
    box-shadow: none;
    border-radius: 1em;
}
.total_item {
    margin: .8em;
    font-size: 1.5em;
}
.cart_title {
    margin: .8em;
    font-size: 1em;
    font-weight: 700;
    color: green;
}
.items {
    width: 90%;
    margin: 0 auto;
}
.item {
    margin: 2em 0;
}
.item hr {
    margin-top: 1.5em;
}
hr {
    height: .06em;
    border: none;
    color: #8bdc19;
    background-color: #8bdc19;
}
.checkout {
    border-radius: .2em;
    margin-top: 1em;
    padding: 0 3%;
    box-shadow: none;
    background-color: #F9FAFB;
}
.total_item {
    margin-left: 0;
    color: green;
}
p {
    margin: 0;
}
.total_price {
    display: flex;
    flex-direction: row;
    margin: 1.3em 0 ;
    justify-content: space-between;
    align-items: center;

}
.order_total {
    font-size: 1.2em;
    font-weight: 600;
    color: green;
}
.make_order_container {
    background-color: white;
    padding: .5em;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}
.make_order {
    width: 100%;
    height: 2.5em;
    margin: 1.3em 0 1em 0;
    font-size: 1.1em;
    font-weight: 600;
    padding: .5em;
    border-radius: .3em;
    font-weight: 600;
}
/* .make_order:disabled {
    color: black;
} */
.no_cart {
    width: 100%;
    margin: 2em auto;
    text-align: center;
}
.no_cart p {
    margin: 1em auto;
    font-size: 1.3em;
    font-weight: 500;
}
.no_cart Button {
    font-weight: 600;
    width: 80%;
    height: 3em;
    font-size: 1.1em;
    color: green;
}
.t_label {
    font-size: 1em;
}
.t_price {
    font-size: 1em;
    color: green;
    font-weight: 500;
}

@media only screen and (min-width: 481px) {
    .cart_container {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin: 5em auto 2em auto;
        z-index: 0;
        justify-content: space-between;
    }
    .item_list {
        flex: .6;
        box-shadow: none;
        border-radius: 1em;
    }
    .total_item {
        margin: .8em;
        margin-left: 0;
        font-size: 1.3em;
        color: green;
    }
    .cart_title {
        margin: 1em .8em;
        font-size: 1.4em;
        font-weight: 600;
        color: green;
    }
    .items {
        width: 90%;
        margin: 0 auto;
    }
    .item {
        margin: 2em 0;
    }
    .item hr {
        margin-top: 1.5em;
    }
    hr {
        height: .06em;
        border: none;
        color: #8bdc19;
        background-color: #8bdc19;
    }
    .checkout {
        position: static;
        flex: .35;
        border-radius: .2em;
        margin-left: 3em;
        margin-top: 5em;
        z-index: 0;
        padding: 0 3%;
        box-shadow: none;
        background-color: #F9FAFB;
        max-height: 23em;
    }
    .checkoutt {
        position: static;
        flex: .35;
        border-radius: .2em;
        margin-left: 3em;
        margin-top: 5em;
        z-index: 0;
        padding: 0 3%;
        box-shadow: none;
        background-color: #F9FAFB;
        max-height: 10em;
    }
    p {
        margin: 0;
    }
    .total_price {
        display: flex;
        flex-direction: row;
        margin: 1.3em 0 ;
        justify-content: space-between;
        font-size: 1em;
        align-items: center;
    }
    .t_label {
        font-size: 1em;
    }
    .t_price {
        font-size: 1em;
        color: green;
        font-weight: 500;
    }
    .order_total {
        font-size: 1.2em;
        font-weight: 600;
        color: green;
    }
    .make_order_container {
        background-color: #F9FAFB;
        padding: .5em;
        width: 100%;
        position: static;
    }
    .make_order {
        width: 100%;
        height: 2.5em;
        margin: 1.3em 0 1em 0;
        font-size: 1.1em;
        padding: .5em;
        border-radius: .3em;
        font-weight: 600;
    }
    .no_cart {
        width: 60%;
        margin: 4em auto;
    }
    .no_cart p {
        margin: 1em 0;
        font-size: 1.5em;
        font-weight: 500;
    }
    .no_cart Button {
        width: 80%;
        height: 3em;
        font-size: 1.1em;
    }
}