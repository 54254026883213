.cold_room_list_container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1.5em;
    row-gap: .5em;
    margin: auto;
    background-color: white;
}
.no_cold_room {
    text-align: center;
    width: 100%;
    font-size: .2em;
    font-weight: 400;
    padding: 1em 0;
}

@media only screen and (min-width: 481px) {
    .cold_room_list_container {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 2em;
        row-gap: 1.5em;
        margin: auto;
        background-color: white;
    }
    .no_cold_room {
        text-align: center;
        width: 100%;
        font-size: 1.1em;
        font-weight: 400;
        padding: 0;
    }
}