.account_container {
    background-color: white;
    width: 90%;
    margin: 6em auto 0 auto;
}

.content_container {
    background-color: white;
    margin: 1em 0 0 2em;
}

.profile_conatainer {
    margin-bottom: 15em;
}

.tab_detail {
    margin: 2em 0;
}
.tab_title {
    margin-bottom: 1em;
    color: green;
}
.account_info {
    margin: 1.2em 0;
    font-size: 1.1em;
}
.ch_button {
    color: green;
    font-weight: 600;
}
.change_password_input {
    margin-top: 1em;
}

.change_password_label {
    margin-top: .3em;
}
.noerror {
    display: none;
}
.error {
    margin-top: .2em;
    display: block;
}
.empty_title {
    font-size: 1.2em;
    font-weight: 500;
    color: black;
    margin-bottom: .5em;
}
.empty_btn {
    color: green;
}
.order_detail_info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    column-gap: 3em;
}
.order_detail_info p {
    margin-top: .5em;
    font-weight: 1.1em;
}
.order_detail_info p span {
    font-weight: 600;
}

.order_item_label {
    font-size: 1.3em;
    font-weight: 600;
    margin: .5em 0;
}

.order_items {
    margin: .5em 0;
}