.about_us_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 2em auto;

}
.about_us_container img {
    align-self: center;
    width: 20%;
    stroke: green;
}
.about_title {
    font-size: 1.2em;
    font-weight: 600;
    font-weight: 600;
    color: green;
}
.about_desc {
    font-size: 1em;
    line-height: 1.5em;
}
.about_us_container p {
    text-align: center;
    margin: .4em 0;
}

@media only screen and (min-width: 481px) {
    .about_us_container {
        display: flex;
        flex-direction: column;
        width: 33%;
        margin: 2em auto;
    
    }
    .about_us_container img {
        align-self: center;
        width: 15%;
        stroke: green;
    }
    .about_title {
        font-size: 1.1em;
        font-weight: 600;
        color: green;
    }
    .about_desc {
        font-size: 1em;
    }
    .about_us_container p {
        text-align: center;
        margin: .6em 0;
    }
}
