.nav_container {
    width: 100%;
    margin: 1em auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.logo {
    display: none;
    font-size: 1em;
    margin-right: 1em;
}
.nav_icons {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.nav_icon {
    margin-left: 1em;
}
.search_container {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0 5%;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #70A032;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
}
.search_input {
 flex: .9;
 width: 100%;
 margin: 1em 0;
 box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
 border-radius: .5em;
}
.lang_mobile {
    flex: .05;
    display: block;
    
}
.lang_icon {
    color: green;
}

.current_language {
    color: green;
}

@media only screen and (min-width: 481px) {
    .nav_header {
        width: 100%;
        margin: 0;
        padding: 1em 0;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.09);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: white;
    }
    .nav_container {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;  
    }
    Link {
        text-decoration: none;
    }
    /* .nav_name {
        font-size: 1rem;
        margin-left: 2rem;
    } */
    .logo {
        display: block;
        flex: .1;
        font-size: 1.2em;
        color: green;
        font-weight: 700;
    }
    .logo_link {
        text-decoration: none;
    }
    .nav_icons {
        flex: .1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    .nav_icon {
        font-size: 1.4em;
        color: black;
        text-align: center;
        color: green;
    }
    .cart_menu {
        margin-top: .2em;
    }
    .cart {
        position: relative;
    }
    .account_icon {
        font-size: 1.6em;
        text-align: center;
        color: green;
    }
    .lang_icon {
        color: green;
    }
    .logged_user {
        font-size: .8em;
        margin: 0 1em;
    }
    .logged_user span {
        font-size: 1.2em;
        font-weight: 600;
    }
    .circle {
        position: absolute;
        top: -.5rem;
        right: -.8rem;
        background-color: orangered;
        text-align: center;
        border-radius: 100%;
        width: 1.2rem;
        height: 1.2rem;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.09);
    }
    .circle_text {
        font-size: .8rem;
        font-weight: 700;
        color: white;
        margin: .2rem;
    }
    .search_container {
        flex: .8;
        margin: 0 auto;
        position: static;
        background-color: white;
        box-shadow: none;
    }
    .search_input {
     width: 100%;
     margin: 0;
     box-shadow: none;
     border-radius: 0;
    }
    .search_icon {
        margin-top: .3em;
        color: white;
        width: 1.7em;
        height: 1.7em;
        font-weight: 600;
    }
    .current_language {
        color: green;
    }
    .logged_user {
        color: green;
    }
    .lang_mobile {
        display: none;
    }
}

