.footer_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #8bdc19;
    padding: 0 0 2em 0;
    color: white;
    margin-top: 5em;
}
.footer_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1em auto;
    justify-content: space-between;
    font-size: 1em;
    align-items: flex-start;
    font-weight: 600;
}
.footer_menu_item {
    width: 100%;
    padding: .7em 1em;
    margin: .5em 0;
    font-size: 1.3em;
}
a {
    padding: 0;
    margin: 0;
    font-size: 1em;
    color: white;
    text-decoration: none;
}
.social_links {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1em auto;
    justify-content: space-between;
    font-size: 1.8em;
    align-items: center;
}
.developer_info {
    width: 90%;
    margin: 1em auto 4em auto;
    font-size: 1.3em;
    text-align: center;
    line-height: 1.4em;
}


@media only screen and (min-width: 481px) {
    .footer_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #8bdc19;
        padding: 2em 0;
        color: white;
        margin-top: 7em;
    }
    .footer_menu {
        display: flex;
        flex-direction: row;
        width: 45%;
        margin: 1em auto;
        justify-content: space-between;
        font-size: 1em;
        align-items: center;
        font-weight: 600;
    }
    .footer_menu_item {
        width: auto;
        border: none;
        padding: 0;
        margin: 0;
        font-size: 1em;
        box-shadow: none;
        background-color: #8bdc19;
    }
    a {
        padding: 0;
        margin: 0;
        font-size: 1em;
        color: white;
        text-decoration: none;
    }
    .social_links {
        display: flex;
        flex-direction: row;
        width: 15%;
        margin: 1em auto;
        justify-content: space-between;
        font-size: 1em;
        align-items: center;
    }
    .developer_info {
        width: 30%;
        margin: 1em auto;
        font-size: 1em;
    }
    .social {
        color: white;
        text-decoration: none;
    }
    .social:hover {
        color: black;
    }
}