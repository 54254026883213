.product_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em .3em;
    text-align: center;
    background-color: white;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
    border-radius: .5em;
    padding: 1em 0;
}
.product_image_container {
    width: 100%;
    border-radius: .5em;
    padding: .4em;
}
.product_image_container img {
    max-width: 100%;
    height: 4em;
    margin: auto;
    /* border: 1px solid black; */
}
.product_container p {
    font-size: 1em;
}

@media only screen and (min-width: 481px) {
    .product_container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        text-align: center;
        background-color: white;
        box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
        border-radius: 1em;
    }
    .product_container:hover {
        background-color: orange;
        color: white;
    }
    .product_image_container {
        width: 100%;
        border-radius: .5em;
        border: none;
        padding: 0;
        
    }
    .product_image_container img {
        max-width: 90%;
        height: 7em;
        margin: 1em auto;
        border-radius: 1em;
    }
    .product_container p {
        font-size: 1.2em;
    }
    
    

} 