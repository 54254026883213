.product_detail_container {
    width: 95%;
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
.product_image {
   margin: 4em 0 0 0;
   width: 100%;
   margin: 0 auto;
   align-self: center;
}
.product_image img {
    width: 70%;
    margin: 0 15%;
}
.product_detail {
    width: 95%;
    margin: 0;
}
.product_name {
    font-size: 1.6em;
    font-weight: 600;
    margin: 1em 0 0 0;
    color: green;
}
.title {
    font-size: 1.3em;
    font-weight: 400;
    margin: 2em 0 1em 0;
}
.type_container {
    width: 100%;
    margin: 0 .5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.radio_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.type_radio {
}
.type {
    display: flex;
    flex-direction: row;
}
.product_price {
    width: 100%;
}
.price_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.price { 
    background-color: white;
    padding: .5em .5em;
    border: .01em solid gray;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.cold_room_name {
    font-size: 1.2em;
}
.price_amount {
    font-size: 1.2em;
    font-weight: 600;
}
.price p {
    margin: .2em 0;
    padding: 0;
}
.price_active {
    background-color: green;
    color: white;
    padding: .5em .5em;
    box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.price_active p {
    margin: .2em 0;
    padding: 0;
}
.description {
    font-size: 1.2em;
    line-height: 2em;
}
.q_av {
    width: 100%;
    display: flex;
    align-items: center;
}
.av {
    margin: 1em 0 0 3em;
}
.quantity_description{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.quantity_label {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
.quantity_amount {
    font-size: 1.2em;
    margin: 0 0 0 1em;
    padding: 0;
}
.product_quantity_container {
    width: 100%;
    margin: 0 .5em;
    align-self: center;
}
.product_quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.quantity_input {
    margin: 1.4em 0 0 1em;
}
.quantity_out_stock {
    margin: 1.1em 0 0 2em;
    font-size: 1.2em;
    font-weight: 600;
}
.action_to_order {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1em;
    margin: 4em 0 0 0;
}
.detail_btn {
    margin: .8em 0;
    padding: 1em 0;
    font-size: 1.5em;
    font-weight: 600;
}
.detail_btn:disabled {
    border: none;
}
.add_to_truck {
    /* background-color: orange; */
    border: none;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.add_to_cart_text {
    margin: 0 .5em;
}
.buy_now {
    background-color: white;
    /* border: 2px solid orange; */
    color: green;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.modal_no_button {
    background-color: white;
    border: 1px solid orange;
    color: green;
}

.modal_yes_button {
    background-color: orange;
    color: white;
}
.type_radio {
    margin-bottom: .5em;
}

.product_detail_loading_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2em 0;
}

.product_image_loading {
    width: 100%;
}
.product_info_loading {
    margin-top: .5em;
    width: 100%;
}
.part_loading {
    display: flex;
    flex-direction: row;
}
.part {
    margin-right: .2em;
}

@media only screen and (min-width: 481px) {
    .product_details {
        width: 100%;
    }
    .product_detail_container {
        width: 90%;
        margin: 3em auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
    }
    .product_image {
       margin: 5em 0 0 0;
       flex: .3;
       width: 100%;
       align-self: start;
    }
    .product_image img {
        max-width: 100%;
        margin: 0;
    }
    .product_detail {
        flex: .6;
        width: 50%;
        margin: 0;
    }
    .product_name {
        font-size: 1.6em;
        font-weight: 600;
        margin: 1em 0 0 0;
    }
    .title {
        font-size: 1em;
        font-weight: 500;
        margin: 2em 0 1em 0;
    }
    .type_container {
        width: 70%;
        margin: 0 .5em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .radio_group {
        display: flex;
        flex-direction: row;
    }
    .type {
        display: flex;
        flex-direction: row;
    }
    .product_price {
        width: 100%;
    }
    .price_container {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .price { 
        flex: .25;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        padding: .5em .5em;
        border: .01em solid gray;
        justify-content: space-between;
        box-shadow: 2px -2px 4px rgb(0 0 0 / 0.1);
        border-radius: 1em;
    }
    .cold_room_name {
        font-size: .8em;
    }
    .price_amount {
        font-size: .9em;
        font-weight: 600;
    }
    .price p {
        margin: 0;
        padding: 0;
    }
    .price_active {
        background-color: green;
        color: white;
    }
    .price_active p {
        margin: 0;
        padding: 0;
    }
    .description {
        font-size: 1em;
        line-height: 2em;
    }
    .quantity_description{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0;
    }
    .quantity_label {
        font-size: 1.2em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }
    .quantity_amount {
        font-size: 1.2em;
        margin: 0 0 0 1em;
        padding: 0;
    }
    .product_quantity_container {
        width: 40%;
        margin: 1em 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .product_quantity {
        margin: 1em 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0;
    }
    .quantity_input {
        margin: 1.3em 0 0 1em;
    }
    .action_to_order {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1em 0;
    }
    .detail_btn {
        flex: .4;
        font-size: 1.2em;
        padding: 1em;
        margin-right: 1em;
    }
    .detail_btn:hover {
        /* background-color: green;
        color: white; */
        border: none;
    }
    .detail_btn:disabled {
        border: none;
    }
    .add_to_truck {
        /* background-color: orange; */
        border: none;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .buy_now {
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .type_radio {
        margin-bottom: 0;
    }

    .product_detail_loading_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 4em 0;
    }

    .product_image_loading {
        width: 100%;
        flex: .3;
    }
    .product_info_loading {
        flex: .7;
        width: 100%;
        margin-left: 3em;
    }
    .part_loading {
        display: flex;
        flex-direction: row;
    }
    .part {
        margin-right: .2em;
    }
    .quantity_out_stock {
        margin: 1em 0 0 2em;
        font-size: 1em;
        font-weight: 400;
    }
}