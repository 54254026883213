.signup_container {
    width: 90%;
    margin: .5em auto 0 auto;
}
.signup_container h1 {
    text-align: center;
    font-size: 1.5em;
    padding: 0;
    margin: 1em 0 0 0;
    color: #062539;
}
.signup_form {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 0.03);
    border-radius: .5em;
    padding: 0;
    border: none;
    margin-top: .5em;
}
.signup_input_group {
    width: 90%;
    margin: 1em auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.signup_radio_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 1.3em 5% 0 5%;
}
.radio_label {
    margin-right: 2em;
}
.input_label {
    margin-bottom: 1em;
}
.signup_submit {
    width: 90%;
    margin: .5em auto;
}
.signup_input{
    width: 100%;
    margin: 1em 0;
    border-radius: .3em;
    font-size: 1.1em;
    font-weight: 600;
    height: 2.2em;
    color: white;
    border: none;
    text-align: center;
}
.signin {
    width: 90%;
    display: flex;
    margin: 0 auto 4em auto;
}
.link {
    text-decoration: none;
    color: green;
    font-size: 600;
}
@media only screen and (min-width: 481px) {
    .signup_container {
        width: 40%;
        margin: .5em auto 0 auto;
    }
    .signup_container h1 {
        text-align: center;
        font-size: 1.5em;
        padding: 0;
        margin: 1em 0 0 0;
        color: #062539;
    }
    .signup_form {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 0.03);
        border-radius: .5em;
        padding: 0;
        border: none;
        margin-top: .5em;
    }
    .signup_input_group {
        width: 90%;
        margin: 1.3em auto 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .signup_radio_group {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        margin: 1.3em 5% 0 5%;
    }
    .radio_label {
        margin-right: 2em;
    }
    .input_label {
        margin-bottom: 1em;
    }
    .signup_submit {
        width: 90%;
        margin: .5em auto;
    }
    .signup_input{
        width: 100%;
        margin: 1em 0;
        border-radius: .3em;
        font-size: 1.1em;
        height: 2.2em;
        color: white;
        border: none;
        text-align: center;
    }
    .signin {
        width: 90%;
        display: flex;
        margin: 0 auto 4em auto;
    }
    .link {
        text-decoration: none;
    }
}